<template>
    <div class="d-flex flex-row">
        <div class="o365-new-records-header px-1 py-1 d-flex"
            :style="{'width': gridControl.columns.leftPinnedWidth + gridControl.columns.centerWidth + 'px'}">
            <span class="ms-2 align-self-center">{{$t('Add row(s) here ...')}}</span>
            <button class="btn btn-sm btn-outline-primary ms-4" 
                :disabled="!(gridControl.dataObject.batchData.length > 1)"
                @click="gridControl.closeBatchRecords()"
                :title="$t('Save rows to the Database')">{{ $t('Commit') }}</button>
        </div>
        <div v-if="gridControl.columns.hasPinnedRightColumns" class="o365-new-records-header-right h-100 ms-auto" :style="{'min-width': gridControl.columns.rightPinnedWidth+1 + 'px'}"></div>
    </div>

    <div class="d-flex flex-row" style="overflow-y: auto;">
        <div class="o365-grid-body">
            <BaseDataList :data="data" :dataLength="data.length" :rowHeight="gridControl.props.rowHeight" disableDataTransformations :getViewportContainer="setListRef" disableScrollHandler>
                 <template #left="{row}">
                    <NewRecord :row="row" :columns="gridControl.columns.leftColumns"/>
                 </template>
                 <template #center="{row}">
                    <NewRecord :row="row" :columns="gridControl.columns.centerColumns"/>
                 </template>
                 <template #right="{row}">
                    <NewRecord :row="row" :columns="gridControl.columns.rightColumns"/>
                 </template>
            </BaseDataList>
        </div>
    </div>

    <div class="o365-new-records-footer" :style="{'max-width': gridControl.columns.leftPinnedWidth + gridControl.columns.centerWidth + 'px'}"></div>
</template>

<script setup lang="ts">
import useBaseGridControl from 'o365.vue.composables.Grid.BaseControlInject.ts';
import useDataGridNavigation from 'o365.vue.composables.Grid.BaseNavigation.ts';
import NewRecord from 'o365.vue.components.Grid.NewRecordRow.vue';
import { ref, computed, defineAsyncComponent, onMounted, onUnmounted } from 'vue';

const BaseDataList = defineAsyncComponent(() => import('o365.vue.components.Grid.BaseList.vue'));

const gridControl = useBaseGridControl();

const data = computed(() => {
    return gridControl.value.dataObject.batchData;
});

const listContainerRef = ref<HTMLElement>(null);
const NavigationExtension = await gridControl.value.getExtension('navigation');
const { navigationControl } = useDataGridNavigation({
    containerRef: listContainerRef,
    gridControl: gridControl,
    rowSelector: '.o365-new-record',
    cellSelector: '.o365-body-cell',
    forceEditMode: true,
    enableDrag: false,
    disableSelectionRendering: true
}, NavigationExtension);

function setListRef(el: HTMLElement) {
    listContainerRef.value = el;
}

onMounted(() => {
    if (data.value[0] && typeof data.value[0].index === 'number') {
        navigationControl.focusFirstEditableCell(data.value[0].index);
    }
});

onUnmounted(() => {
    window.setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 500);
});
</script>